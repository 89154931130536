import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";
import HeroEnd from "../../components/hero-end";

const SurgeonsCoachingPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "17.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				daniel: file(
					relativePath: { eq: "testimonials/Claire freemen New.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				andrew: file(relativePath: { eq: "testimonials/selda-koydemir.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title = "Surgeons | Business & Executive Coaching in London";
	const description =
		"As a surgeon, going it alone isn't really an option, is it? What you need is someone by your side who gets it—the ups and downs, the ins and outs of the business world.";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const daniel = data.daniel.childImageSharp.gatsbyImageData;
	const andrew = data.andrew.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Industries",
				item: {
					url: `${siteUrl}/industries`,
					id: `${siteUrl}/industries`,
				},
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Surgeons",
				item: {
					url: `${siteUrl}/industries/surgeons`,
					id: `${siteUrl}/industries/surgeons`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/industries/surgeons`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Surgeons business & executive coaching in London",
						},
					],
				}}
			/>
			<HeroEnd
				isDarken={true}
				backgroundImage={heroImage}
				title="Business and Executive Coach For Surgeons"
				description1=""
				description2=""
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							{/* <p>
                As a professional in a leadership position, you’re constantly
                juggling the priorities of your job with everything else in
                life. You’re likely responsible for strategic thinking,
                overseeing operations, developing new approaches, and much more.
              </p> */}
							<p>
								As a surgeon, going it alone isn't really an option, is it? What
								you need is someone by your side who gets it—the ups and downs,
								the ins and outs of the business world. Someone who can give you
								straight-up feedback, help you map out and hit your goals, and
								stand by you when those tough choices come knocking. This
								person? They’ve got to really know you, have a solid handle on
								our industry, and truly understand the kind of challenges we
								face in the OR and beyond.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								As a surgeon, how can a business and executive coach help you?
							</h3>
							<p>
								I know your world is worlds apart from the regular office setup
								- your "office" is the operating room, and your decisions are
								measured in heartbeats, not boardroom nods. However, throughout
								my 35+ years in the business world, I have helped coach surgeons
								from around the world in starting or scaling with their own
								medical business.
							</p>
							<p>
								Do you find yourself seeking more - more meaning, balance, or
								maybe a new direction that reignites your passion for medicine
								and life outside the hospital?
							</p>
							<p>
								So, how about we explore what's possible together? With a little
								bit of coaching, who knows what you could achieve? Let's get
								started.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">
								What lies ahead for your business?
							</h3>

							<p>
								Imagine you could delve into your own future and see what was in
								store for you. How would things change if you found yourself:
							</p>
							<ul className="pl-4">
								<li>
									Bursting with self-confidence and belief in your abilities?
								</li>
								<li>
									Mastering your leadership and communication skills, crucial
									for leading surgical teams?
								</li>
								<li>
									Making decisive, confident decisions in your business without
									a second thought?
								</li>
								<li>
									Feeling a renewed enthusiasm for your work, rekindling your
									passion for surgery?
								</li>
								<li>
									Or embracing a significant career move or stepping into a more
									challenging role with enthusiasm?
								</li>
							</ul>
							<p>
								Imagine how different life could be if these possibilities
								turned into your reality.
							</p>
							<p>
								What's holding you back from turning these 'what ifs' into
								transformative 'whens'?
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">Is there something holding you back?</h2>
							<ul className="pl-4">
								<li>
									Feeling swamped in your private practice, struggling with the
									weight of critical decisions and complex responsibilities?
								</li>
								<li>
									Transitioning from a hospital setting to managing or expanding
									your own surgical practice?
								</li>
								<li>
									Keen to identify your strengths and areas for development, and
									how to use them to your advantage?
								</li>
								<li>
									In search of that direction in your professional life, whether
									it's launching your practice or taking it to new successes?
								</li>
								<li>
									Or maybe you're at a crossroads, looking for the right
									direction ahead?
								</li>
							</ul>
							<p>
								Particularly in the demanding field of surgery, we face
								obstacles now and then. However, it's our approach to overcoming
								these challenges that are the most meaningful in our surgical
								careers.
							</p>
							<p>
								This is where my business coaching comes into action,
								specifically tailored for surgeons and medical professionals
								looking to start or grow their own practices. My coaching
								sessions offer the insights, strategies, and support you need to
								navigate the complexities of managing a surgical practice,
								allowing you to make decisions that have an impact on your
								career and patient care.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Unlock the potential of business coaching
							</h2>
							<p>
								As a coach that supports surgeons like yourself, my goal is to
								empower you to navigate the distinct opportunities in your
								business. I understand the demands of your schedule, which is
								why our coaching sessions are designed to fit seamlessly into
								your life, accommodating even the busiest of calendars.
							</p>
							<p>
								Our coaching sessions will typically cover topics that are
								critical to your professional and personal development:
							</p>
							<ul className="pl-4">
								<li>
									Enhancing Skills and Personal Growth: Growing your surgical
									business while also focusing on your aspirations and
									wellbeing.
								</li>
								<li>
									Leadership Excellence: Developing leadership qualities that
									not only inspire your team but also foster significant
									advancements in patient care.
								</li>
								<li>
									Effective Practice Management: Mastering the intricacies of
									managing a surgical practice ensuring your business operations
									run smoothly.
								</li>
								<li>
									Career Strategy: Analysing your professional journey within
									the surgical field, identifying pathways for growth and
									fulfilment.
								</li>
							</ul>
							<p>
								Setting us apart from the standard approach, our coaching is
								custom-tailored to meet your specific needs. The sessions are
								aimed at providing you with the clarity, direction, and
								strategies essential for a business in the medical field and for
								making a lasting impact on your patients and colleagues.
							</p>
							<p>
								Why not take the first step and schedule a conversation with me?
								It’s an opportunity to explore your potential without any
								commitment, offering significant benefits for your career and
								beyond. Let’s discuss how we can achieve your goals together.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
								src="../../images/12.jpg"
							/>
							<h2 className="text-primary pb-3">Asking the right questions</h2>

							<p>
								As an executive coach, my job centers around asking the right
								questions and carefully listening to what you say, tapping into
								the true sentiment behind your words and your thought process.
							</p>
							<p>
								I learned how to REALLY listen to my clients in a
								non-judgemental way during my 5 years of counselling and
								psychotherapy training.
							</p>
							<p>
								Executive coaching is all about listening properly without
								judging, I will uncover and support you with any false
								assumptions, fears and life-limiting beliefs. These are the
								things that may be holding you back, like a lack of confidence,
								imposter syndrome etc.
							</p>
							<p>
								Executive coaching like this can help you manage these thoughts
								and feelings, so they no longer impact your personal or
								professional life, while working alongside you to gain focus and
								make a significant impact on your life and business.
							</p>
							<p>
								Empowering you to think about the reasons ‘why you can’, rather
								than ‘why you can’t.’
							</p>
							<p>
								Let’s decide what you want, make a plan and use coaching to make
								it happen!
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">
								Trusting a business and executive coach to help your business
								succeed
							</h2>
							<p>
								Throughout my journey as a self-employed professional, I've seen
								it all—the highs of a business and the unavoidable lows.
								Blending my business savvy, with degrees in Management Studies
								(DMS) and an MBA, alongside five years of deep dive into
								counselling and psychotherapy (focusing on Person-Centred and
								Transactional Analysis), and with nearly forty years making
								achievements in the business world, I've gathered a wealth of
								experience.
							</p>
							<p>
								Now, I'm turning my focus towards you, the surgeons, with all
								this under my belt. I understand the pressures and the precision
								your work demands, whether you are starting a new business or
								wanting to grow your existing one. My coaching sessions offer
								you a unique blend of business insight and emotional
								intelligence to help you to manage the challenges of your
								profession.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Positive feedback from my coaching clients
							</h2>
						</Col>
					</Row>

					<HorizontalTestimonial
						name="Claire Freeman. Consultant Podiatric Surgeon FRCPodS"
						review="How do you choose a business coach? When you know you are stuck, and need help, how do you know who to trust with something as personal and critical as your business? I did a lot of searching and spoke to many different coaches, but when I spoke to Jason I immediately recognised his skill and professionalism. His background, experience and positive attitude have helped me in ways I did not expect. I've learnt so much about myself, the way I run my business and how to effect change. Would I choose him again - absolutely, would I recommend him - absolutely, is my business better off - absolutely, am I still stuck...no."
						linkedIn="https://www.linkedin.com/in/claire-freeman-frcpods-consultant/"
						profilePic={daniel}
					/>

					<div className="iframe-container-new mb-4">
						<iframe
							loading="lazy"
							className="responsive-iframe"
							src="https://www.youtube.com/embed/3YNLOv1N2BE?si=F_NRFzLJRFOw7ud5"
							title="Testimonial | Jason Cornes"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						></iframe>
					</div>
				</Container>
			</section>
			{/* <section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Break the cycle with executive coaching in London
							</h3>
							<p>
								In this 24/7 world, leaders, senior managers, owners and
								executives are so busy that they don’t have the time to devote
								to their own development or to the development of the people who
								work for them. In other words, they’re caught in a vicious cycle
								of always “being busy.”
							</p>
							<p>
								In the same way, some struggle and worry about not being able to
								think clearly. You may have an idea, but you can’t seem to get a
								clear picture on what to do next, or perhaps you need to make
								decisions, but you don’t feel confident that the decision you
								make will be the right one.
							</p>
							<p>
								This is precisely why you need executive coaching services. As
								an executive coach in London, I can help you break the cycle of
								always being busy while giving you the confidence to make
								decisions that positively impact your life.
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Are you ready to take on business success?
							</h2>
							<p>
								Predicting the future of your surgical practice isn't something
								we can do. I can't lay out exactly how things might change if
								you take on new surgical techniques, start each day filled with
								confidence and a sense of purpose, or elevate your team's
								performance to new levels. But one thing is for sure:
							</p>
							<p>If we stick to the old ways, nothing's going to improve.</p>
							<p>
								It's truly amazing what we can achieve together when we use
								coaching to focus and reflect on what you really want to
								accomplish in your surgical business.
							</p>
							<p>
								You've got everything to gain and absolutely nothing to lose, so
								how about we schedule a chat? Let's dive into the potential
								improvements and advancements we can bring to your business and
								professional life as a surgeon.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Book a discovery call today</h3>
							<p>
								Let's chat, get to know each other and see if there's a way for
								us to work together with some business coaching for surgeons.
							</p>
							<p>
								P.S. Regardless of your proficiency in your position, it's clear
								that going it alone isn't an option. Having a reliable executive
								coaching partner by your side to offer impartial feedback,
								assist in goal setting and achievement, and guide you through
								difficult decisions is essential.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default SurgeonsCoachingPage;
